<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back(-1)"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.withdraw") }}</li>
      </ul>
    </div>
    <div v-if="dataList" class="main main_page">
      <div class="Rech">
        <AForm
          layout="vertical"
          :model="formModel"
          ref="formRef"
          :rules="ruleRef"
          validateTrigger="change"
          @submit.prevent="handleConfirm"
          autocomplete="off"
        >
          <div class="Rech_box">
            <ul class="ul_three">
              <h1>{{ t("profile.label.withdrawAmount") }}</h1>
              <li>
                <b class="withdrawalSymbol">{{ CURRENCY_SYMBOL }}</b>
                <AFormItem name="amount">
                  <AInput
                    type="number"
                    v-model:value="formModel.amount"
                    step="any"
                    :placeholder="t('profile.placeholder.withdrawAmount')"
                  />
                </AFormItem>
              </li>
              <li></li>
              <li>
                {{ t("profile.label.balance") }}:
                <b v-if="userBalance">{{ formatMoney(userBalance.balance) }}</b>
                <button @click="withDrawAll" type="button">
                  {{ t("common.button.withdrawAll") }}
                </button>
              </li>
              <li v-if="feeVal">
                {{ t("profile.label.fee") }}:&nbsp;
                <span style="color: red">
                  {{feeVal+'%'}}
                  <!-- {{
                    formatPercentage(
                      +sysDataDictionaryWbObj?.TKSXF?.dataContent
                    )
                  }} -->
                </span>
              </li>
              <li v-if="feeVal">
                {{ t("profile.label.feeAmount") }}:&nbsp;
                <span style="color: red">
                  {{
                    formatMoney(
                      (+feeVal/100) *
                        +formModel.amount
                    )
                  }}
                </span>
              </li>
              <li v-if="feeVal">
                {{ t("profile.label.actualToAccount") }}:&nbsp;
                <span style="color: red">
                  {{
                    formatMoney(
                      (1 - (+feeVal/100)) *
                        +formModel.amount
                    )
                  }}
                </span>
              </li>
            </ul>
          </div>
          <div class="Rech_box">
            <!-- 选择提现方式 -->
              <ul>
                <h3 class="t_h">{{ t("common.title.withdrawal") }}:</h3>
                <li>
                  <AFormItem name="methodType" class="t_div">
                  <a-select v-model:value="formModel.methodType" @change="changeTypeEvent">
                    <a-select-option v-for="(item, index) in dataList" :value="item.methodType" :key="index" >{{ getMethodTypeName(item.methodType)  }}</a-select-option>
                  </a-select>
                </AFormItem>
                </li>
              </ul>
          </div>

          <div class="Rech_box">
            <div class="ul_lisk">
              

              <!-- 银行卡提现 -->
              <template v-if="+formModel.methodType === 4">
                <ul>
                  <h3>{{ t("profile.label.bank") }}:</h3>
                  <li>
                    <AFormItem name="bankName">
                      <AInput readonly v-model:value="bankName" />
                    </AFormItem>
                  </li>
                </ul>
                <ul>
                  <h3>{{ t("profile.label.accountNo") }}:</h3>
                  <li>
                    <AFormItem name="acctNo">
                      <AInput readonly v-model:value="formModel.acctNo" />
                    </AFormItem>
                  </li>
                </ul>
                <ul>
                  <h3>{{ t("profile.label.accountName") }}:</h3>
                  <li>
                    <AFormItem name="acctName">
                      <AInput readonly v-model:value="formModel.acctName" />
                    </AFormItem>
                  </li>
                </ul>
                <!-- <ul>
                  <h3>{{ t("profile.label.contactMobile") }}:</h3>
                  <li>
                    <AFormItem name="contactPhone">
                      <AInput
                        readonly
                        :maxlength="15"
                        v-model:value="formModel.contactPhone" 
                        :placeholder="t('profile.placeholder.contactPhone')"
                      />
                    </AFormItem>
                  </li>
                </ul> -->
              </template>
              <!-- PIX提现 -->
              <template v-else-if="+formModel.methodType === 1">
                <ul>
                  <h3>{{ t("profile.label.pixType") }}</h3>
                  <li>
                    <AFormItem name="bankName">
                      <AInput readonly
                        v-model:value="bankName"
                      />
                    </AFormItem>
                  </li>
                </ul>
                <ul>
                  <h3>{{ t('common.title.Account') }}:</h3>
                  <li>
                    <AInput readonly
                        v-model:value="formModel.acctNo"
                        :placeholder="t('extra.PIX')"
                      />
                  </li>
                </ul>
                <ul>
                  <h3>{{ t("common.title.Name") }}:</h3>
                  <li>
                    <AInput
                        v-model:value="formModel.acctName" readonly
                        :placeholder="t('profile.placeholder.realName')"
                      />
                  </li>
                </ul>
              </template>
              <!-- USDT -->
              <template v-else-if="+formModel.methodType === 2 || +formModel.methodType === 3">
                <ul>
                  <h3>{{ t('common.title.withdrawalAddress') }}:</h3>
                  <li>
                    <AFormItem name="acctNo">
                      <AInput readonly
                        v-model:value="formModel.acctNo"
                        
                      />
                    </AFormItem>
                  </li>
                </ul>
              </template>
              <ul v-if="sysDataDictionaryWbObj?.TKCI?.dataOnOff">
                <h3>{{ sysDataDictionaryWbObj?.TKCI?.dataContent }}:</h3>
                <li>
                  <AFormItem name="userCi">
                    <!-- type="password" -->
                    <AInput
                      v-model:value="formModel.userCi"
                      :placeholder="t('profile.placeholder.fundCI')"
                    />
                  </AFormItem>
                </li>
              </ul>

              <ul>
                <h3>{{ t("profile.label.password") }}:</h3>
                <li>
                  <AFormItem name="fundPassword">
                    <AInput
                      type="password"
                      v-model:value="formModel.fundPassword"
                      :placeholder="t('profile.placeholder.fundPassword')"
                    />
                  </AFormItem>
                </li>
              </ul>
            </div>
          </div>
          <div class="Sys_tips c-999 text-pre-line">
            {{ t("common.text.withdraw") }}
          </div>
          <div class="Sys_submit_btn withdraw pb-30 Sys_submit_btn_c">
            <button type="submit" :disabled="!validateStatus">
              {{ t("common.button.withdraw") }}
            </button>
          </div>
        </AForm>
      </div>
    </div>
    <Loading :loading="loading" />
  </div>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  onMounted,
  getCurrentInstance,
  computed,
} from "vue";
import { message, Form } from "ant-design-vue";
const useForm = Form.useForm;
import { useRouter } from "vue-router";
import { formatMoney, formatPercentage } from "@/utils/formatter";
import { useI18n } from "@/lang";
import Loading from "@/components/Loading.vue";
import { CURRENCY_SYMBOL } from "@/utils/constant";
import api from "@/api";
import { userDetail } from "@/hooks/userDetail";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  components: { Loading },
  setup() {
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const userBalance = ref({ balance: userDetail.value?.amount });
    const userBankcard = ref({});
    const loading = ref(false);
    const myBankId = ref(null);
    const bankName = ref(null);
    const feeVal = ref(null);
    const pixTypes = ref(['CPF', 'CNPJ', 'PHONE', 'EMAIL', 'EVP']);
    
    let formModel = reactive({
      amount: null,
      contactPhone: userBankcard.value.contactPhone,
      acctName: userBankcard.value.acctName,
      acctNo: userBankcard.value.acctNo,
      fundPassword: null,
      PIX: null,
      realName: null,
      methodType:null,
      userCi: null
    });

    const expect = reactive({
      balance: 0,
    });

    let dataList = ref(null);
    onMounted(async () => {
      let res = await api.getUserWithdrawal();
      let data = res?.data?.data?.[0];
      if (data) {
        dataList.value = res.data?.data || [];

        userBankcard.value = {
          contactPhone: data.contactPhone,
          acctName: data.openAccountName,
          acctNo: data.bankCord,
        };
        formModel.methodType = data.methodType
        bankName.value = data.bankName;
        myBankId.value = data.myBankId;
        feeVal.value = data.methodRate
      }
      if (+userDetail.value?.paymentPassword != 1) {
        message.info(t("common.message.withdrawPasswordRequire"));
        router.replace({
          path: `/profile/Withdraw_password`,
          query: { back: 1 },
        });
      } else if (!data && !dataList.value) {
        message.info(t("common.message.bankCardRequire"));
        router.replace({
          path: `/profile/Withdrawal`,
          query: { back: 1 },
        });
      }
    });

    const ruleRef = reactive({
      amount: [
        {
          required: true,
          message: t("profile.placeholder.withdrawAmount"),
        },
        {
          validator: async (_rule, val) => {
            if (+val < 0.01 || +val > expect.balance) {
              return Promise.reject(t("profile.placeholder.excessBalance"));
            } else {
              if (
                ("" + val).indexOf(".") != -1 &&
                ("" + val).slice(("" + val).indexOf(".")).length >= 4
              ) {
                return Promise.reject(t("error.recharge.decimalErr"));
              }
              return Promise.resolve();
            }
          },
        },
      ],
      // acctName: [
      //   {
      //     required: true,
      //     message: t("profile.placeholder.accountName"),
      //   },
      // ],
      // acctNo: [
      //   {
      //     required: true,
      //     message: t("profile.placeholder.accountNo"),
      //   },
      // ],
      // contactPhone: [
      //   {
      //     required: true,
      //     message: t("profile.placeholder.contactPhone"),
      //   },
      //   {
      //     validator: (rule, value) => {
      //       return RE_PHONE_NUMBER.test(value)
      //         ? Promise.resolve()
      //         : Promise.reject(t("profile.placeholder.validPhoneNumber"));
      //     },
      //   },
      // ],
      methodType: [
        {
          required: true,
          message: t("profile.placeholder.withdrawalType"),
        },
      ],
      // PIX: [
      //   {
      //     required: true,
      //     message: t("extra.PIX"),
      //   },
      // ],
      // realName: [
      //   {
      //     required: true,
      //     message: t("profile.placeholder.realName"),
      //   },
      // ],
      fundPassword: [
        {
          required: true,
          message: t("profile.placeholder.fundPassword"),
        },
        {
          min: 4,
          max: 30,
          message:
            t("extra.minLength", { min: 4 }) +
            t("extra.maxLength", { max: 30 }),
          trigger: "blur",
        },
      ],
    });
    const { validateInfos } = useForm(formModel, ruleRef);

    const validateStatus = computed(() => {
      if (+formModel.methodType !== 1) {
        return (
          // validateInfos.amount?.validateStatus === "success" &&
          // validateInfos.acctName?.validateStatus === "success" &&
          // validateInfos.acctNo?.validateStatus === "success" &&
          // validateInfos.contactPhone?.validateStatus === "success" &&
          validateInfos.fundPassword?.validateStatus === "success"
        );
      } else {
        return (
          // validateInfos.PIX?.validateStatus === "success" &&
          // validateInfos.realName?.validateStatus === "success" &&
          validateInfos.fundPassword?.validateStatus === "success"
        );
      }
    });
    watch(userBankcard, (newCard) => {
      if (newCard) {
        formModel.acctName = newCard.acctName;
        formModel.acctNo = newCard.acctNo;
        formModel.contactPhone = newCard.contactPhone;
      }
    });

    watch([formModel, userBalance], () => {
      if (userBalance.value) {
        expect.balance = userBalance.value.balance;
      }
    });

    if (userBankcard.value && userBankcard.value?.acctName) {
      formModel.acctName = userBankcard.value.acctName;
      formModel.acctNo = userBankcard.value.acctNo;
      formModel.contactPhone = userBankcard.value.contactPhone;
    }

    async function handleConfirm() {
      // 验证
      let validateResult = await proxy.$refs["formRef"]
        .validate()
        .then((e) => {
          return e;
        })
        .catch((e) => {
          return e;
        });
      if (
        !(
          validateResult &&
          validateResult.errorFields &&
          validateResult.errorFields.length === 0
        )
      ) {
        return;
      }
      if (
        +sysDataDictionaryWbObj.value?.TKZDJE?.dataContent >
          +formModel.amount ||
        +sysDataDictionaryWbObj.value?.TKZGJE?.dataContent < +formModel.amount
      ) {
        message.info(
          t("error.withDraw.amountErr", {
            max: sysDataDictionaryWbObj.value?.TKZGJE?.dataContent,
            min: sysDataDictionaryWbObj.value?.TKZDJE?.dataContent,
          })
        );
        return;
      }
      // console.log(''+sysDataDictionaryWbObj.value?.ZCQHPZ?.dataContent !== '51','-----1',sysDataDictionaryWbObj.value?.ZCQHPZ?.dataContent)
      if (sysDataDictionaryWbObj.value?.TKCI?.dataOnOff && (!formModel.userCi || formModel.userCi.length <= 0)) {
        message.info(t('profile.placeholder.fundCI'))
        return;
      }
      submitWithdraw(formModel);
    }

    async function submitWithdraw() {
      loading.value = true;
      let formToSend = {
        amount: formModel.amount,
        password: formModel.fundPassword,
      };
      formToSend.myBankId = myBankId.value
      if (+sysDataDictionaryWbObj.value?.TKCI?.dataOnOff > 0){
        formToSend.userCi = formModel.userCi
      }
      if (+formModel.methodType === 1 || +formModel.methodType === 4) {
        formToSend.account = formModel.acctNo
        formToSend.realName = formModel.acctName
      }else{
        formToSend.account = formModel.acctNo
        // formToSend.realName = formModel.acctName
      }
      
      api
        .withdrawal(formToSend)
        .then((res) => {
          loading.value = false;
          if (res?.data?.success) {
            api.getUserDetails();
            message.success(t("common.message.withdrawSuccess"));
            router.replace("/home");
          }
        })
        .catch(() => {
          loading.value = false;
          message.error(t("common.message.withdrawFailed"));
        });
    }

    function changeTypeEvent(){
      
      // console.log(''+formModel.methodType,dataList.value.find(item => +item.methodType === +formModel.methodType))
      let data = dataList.value.find(item => +item.methodType === +formModel.methodType)

      userBankcard.value = {
        contactPhone: data.contactPhone,
        acctName: data.openAccountName,
        acctNo: data.bankCord,
      };
      // formModel.methodType = data.methodType
      bankName.value = data.bankName;
      myBankId.value = data.myBankId;
      feeVal.value = data.methodRate
    }

    function withDrawAll() {
      // console.log(userBalance.value)
      formModel.amount = formatMoney(userBalance.value.balance,false)
    }

    function getMethodTypeName(type){
      let nameStr = ''
      switch (+type) {
        case 1:
          nameStr = t('profile.label.methodType_1')
          break;
        case 2:
          nameStr = t('profile.label.methodType_2')
          break;
        case 3:
          nameStr = t('profile.label.methodType_3')
          break;
        case 4:
          nameStr = t('profile.label.methodType_4')
          break;
      }
      return nameStr
    }

    return {
      t,
      userBankcard,
      userBalance,
      formModel,
      ruleRef,
      handleConfirm,
      formatMoney,
      withDrawAll,
      loading,
      validateStatus,
      bankName,
      CURRENCY_SYMBOL,
      formatPercentage,
      sysDataDictionaryWbObj,
      dataList,
      getMethodTypeName,
      changeTypeEvent,
      pixTypes,
      feeVal
    };
  },
};
</script>

<style lang="scss">
.withdrawalSymbol{
  position: relative;
  z-index: 100;
}
.text-pre-line {
  white-space: pre-line;
}

.ant-input {
  border-bottom: none !important;
}

.ant-input:focus {
  border-bottom: none !important;
}

.ant-form-item-children input:focus {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

.ant-form-vertical .ant-form-explain {
  // margin-top: -15px !important;
}

.Sys_submit_btn.withdraw {
  button {
    &[disabled] {
      background: #a0a0a0;
    }
  }
}
.t_h{
  position: relative;
  top: 32px;
  left: 0;
  
  // width: 30px;
  font-size: 3.2vw;
  font-weight: normal;
  line-height: 3.2vw;
  color: #666;
  transform: translateY(-50%);
}
.t_div{
  position: relative;
  top:4px;
  width: 60%;
  left:30%;
  z-index: 2;
}
</style>
